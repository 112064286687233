<template>
  <v-dialog v-model="show" persistent :fullscreen="isPhone" max-width="800">
    <v-card class="d-flex flex-column noscroll">
      <BaseCloseDialogHeader heading="Build Cache" @cancel="cancel" />
      <v-card-text class="silver--text pt-2 pb-0 px-2 ">
        <v-row class="pb-0 pt-0" dense>
          <v-col cols="12" class="px-0">
            <v-card-actions :class="textSizeLarge">
              <v-spacer />
              {{ percent }}%
              <v-spacer />
            </v-card-actions>
            <v-card-actions :class="textSizeLarge">
              <v-btn
                large
                block
                class="charcoal paper--text"
                @click="buildCache"
              >
                Go
              </v-btn>
            </v-card-actions>
            <v-card
              rounded
              flat
              v-for="item in urls"
              :key="item.url"
              class="px-0 mx-2 py-1 lightHeader progressActive--text mb-2"
            >
              <v-card-actions
                :class="textSizeSmall + ' px-1 pt-1 pb-0 progressActive--text'"
              >
                {{ item.url }}
                <v-spacer />
                <v-icon
                  v-if="item.completed == 'Yes'"
                  color="progressActive"
                  right
                >
                  mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-spacer />
      <v-card-actions class="px-0">
        <BaseActionButton
          icon="mdi-close"
          label="Cancel"
          color="info"
          plain
          :large="!isPhone"
          @clickedThis="cancel"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
//import { AxiosBatch } from 'axios-batch'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      switchNum: 0,
      selectedExercise: null,
      exercise: '',
      len: 0,
      percent: 0,
      urls: []
    }
  },
  watch: {
    show: function(val) {
      if (!val) {
        this.reset()
        this.loadUrls()
      }
    }
  },
  beforeMount() {
    this.loadUrls()
  },

  computed: {
    itemsCount() {
      return this.urls.length
    },
    ...appConfig
  },
  methods: {
    reset() {
      this.len = 0
      this.urls = []
      this.percent = 0
    },
    cancel() {
      this.$emit('cancel')
    },

    async buildCache() {
      //const client = axios.create({ baseURL: this.baseURL })
      //const ab = new AxiosBatch({ baseURL: this.baseURL })

      let requests = new Array()
      let i = 0
      this.urls.forEach(el => {
        requests.push({ url: el.url, id: i })
        ++i
      })

      this.len = this.urls.length
     // const res = await ab.axiosBatch({ urls: requests })
      /*
      this.urls.forEach(el => {
        return axios.get(this.baseURL + el.url, {}).then(response => {
          if (response.status == 200) {
            el.completed = 'Yes'
            ++i
            this.percent = this.twoDigits((100 * i) / this.len)
          }
        })
        
        //
      })
      */
    },

    loadUrls() {
      return axios.get(this.baseURL + '/util/cacheable', {}).then(response => {
        if (response.status == 200) {
          this.urls = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
